@import '~antd/dist/antd.css';
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin:0; padding:0; }
body{
    user-select: none;
}
#root{
    height: 100%;
    overflow: hidden;
}
.ant-select-tree-title{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
#overallLoadingWidget{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: .5);
}